<template>
	<div class="mb-20">
		<div class="card scan-bar">
			<b-form @submit.prevent="scanAWB(awb)" class="card__content overflow-visible">
				<div class="row d-flex justify-content-center">
					<div class="col-md-5">
						<b-form-input v-model="awb" @keypress.enter.prevent="scanAWB(awb)" type="text" :autofocus="awb ? false : true" placeholder="Scan or Enter AWB" required trim></b-form-input>
						<div class="input-scan" style="margin-top: -40px;margin-right: 8px;">
							<svg tabindex="2" xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
								<path
									d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z">
								</path>
							</svg>
						</div>
					</div>
				</div>
			</b-form>
		</div>
		<div class="card mt-10">
			<b-table :items="items" :fields="fields" sticky-header no-border-collapse class="in-scan-table">
				<template #cell(index)="data">
					{{ data.index + 1 }}.
				</template>
				<template v-slot:cell(action)="data">
					<b-button @click="printLabel(data.item)" variant="primary">
						Print Label
					</b-button>
				</template>
			</b-table>
		</div>
		<div class="pagination-bar">
			<div class="container-fluid mp">
				<div class="row mp">
					<div class="col-4 mp">
						<div class="d-inline-flex wsnw align-items-center">
							<div class="d-block w50px">
								<multiselect class="pagination-items__pp" v-model="perPage" :searchable="false" :show-labels="false" :allow-empty="false" :options="pageOptions" @input="setPageNumber">
								</multiselect>
							</div>
							<span class="pl-8 fs12 lh24 text-gray-600 pagination-items"></span>
						</div>
					</div>
					<div class="col-8 text-right mp">
						<div class="d-inline-flex ml-auto wsnw align-items-center">
							<div class="fs12 lh24 text-gray-600 pr-14 per-page">
								Page: {{ currentPage }}
							</div>
							<b-button-group>
								<b-button class="common-btn" @click="prevPage" :disabled="prevDisabled" variant="paginate left">
									<i class="icon-back fs12"></i>
									<b-icon-arrow-left-square-fill></b-icon-arrow-left-square-fill>
								</b-button>
								<b-button class="common-btn" @click="nextPage" :disabled="nextDisabled" variant="paginate right">
									<i class="icon-arrow fs12"></i>
									<b-icon-arrow-right-square-fill></b-icon-arrow-right-square-fill>
								</b-button>
							</b-button-group>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ResultNotFound v-if="items.length == 0 && !loading" />
		<Loader v-if="loading" :message.sync="message" />
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ResultNotFound from "@/components/ResultNotFound.vue";
import Loader from "@/components/Loader.vue";

export default {
	name: 'ImportOriginScan',
	title: 'ImportOriginScan',
	components: {
		Multiselect,
		ResultNotFound,
		Loader
	},
	data() {
		return {
			user: this.$store.getters.user ?? {},
			awb: '',
			loading: null,
			message: null,
			pageOptions: [20, 40, 80],
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			totalPages: 1,
			prevDisabled: true,
			nextDisabled: true,
			startPage: 1,
			pagination: {
				hasNext: true,
				hasPrev: true,
			},
			items: [],
			fields: [
				{
					key: 'index',
					label: 'S.N.'
				},
				{
					key: 'sy_awb',
					label: 'Shypmax ID / HAWB'
				},
				{
					key: 'fm_awb',
					label: 'FM AWB'
				},
				{
					key: 'origin_scan_date',
					label: 'Scan Date'
				},
				{
					key: 'action',
					label: 'Action'
				}
			]
		}
	},

	mounted() {
		this.getInScanList();
	},

	methods: {

		async getInScanList() {
			if (Number(this.user?.db_id) != 254) {
				try {
					const result = await this.axios.get(`/customer/scan?page=${this.currentPage}&limit=${this.perPage}&status=0`);
					if (result.data.success) {
						this.items = result.data.data;
						this.pagination.hasNext = result?.data?.hasNext;
						this.pagination.hasPrev = result?.data?.hasPrev;
						this.setPagination();
					}
					else {
						this.popToast('Failed!', result.data.message, 'danger')
					}
				}
				catch (error) {
					this.popToast('Failed!', error.response.data.message, 'danger')
				}
			}
		},

		async scanAWB() {
			this.loading = true;
			try {
				const result = await this.axios.post('/customer/origin-scan', { awb: this.awb });
				if (result.data.success) {
					this.popToast("Success!", result.data.message);
					this.getInScanList();
					if (Number(this.user?.db_id) == 254 || result.data?.data?.label_path) {
						this.printLabel({ sy_awb: result.data?.data?.new_awb ?? this.awb, fm_awb: this.awb });
					}
					this.awb = '';
				}
				else {
					this.awb = '';
					this.popToast('Failed!', result.data.message, 'danger')
				}
			}
			catch (error) {
				this.popToast('Failed!', error.response.data.message, 'danger')
			}
			this.loading = false;
		},

		async printLabel(data) {
			this.loading = true;
			try {
				const result = await this.axios.post('/customer/download-label', data);
				if (result.data.success) {
					window.open(result.data.data.path);
					this.popToast("Success", result.data.message);
				}
				else {
					this.popToast('Failed!', result.data.message, 'danger')
				}
			}
			catch (error) {
				this.popToast('Failed!', error.response.data.message, 'danger')
			}
			this.loading = false;
		},

		setPagination() {
			this.nextDisabled = this.pagination.hasNext == true ? false : true;
			this.prevDisabled = this.pagination.hasPrev == true ? false : true;
		},

		setPageNumber() {
			this.currentPage = 1;
			this.getInScanList();
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
			this.currentPage = 1;
			this.prevDisabled = true;
			if (this.totalPages == 1) {
				this.nextDisabled = true;
			}
			else if (this.totalPages > 1) {
				this.nextDisabled = false;
			}
		},

		updatePageNumber() {
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
		},

		nextPage() {
			this.currentPage++;
			this.getInScanList();
		},

		prevPage() {
			this.currentPage--;
			this.getInScanList();
		},
	},
	computed: {
		rows() {
			return this.items.length;
		}
	},
	watch: {
		currentPage: function () {
			this.prevDisabled = false;
			this.nextDisabled = false;
			if (this.currentPage == 1) {
				this.prevDisabled = true;
			}
			if (this.currentPage == this.totalPages) {
				this.nextDisabled = true;
			}
		},
	}
}
</script>

<style lang="scss" scoped>
::v-deep .in-scan-table {
	max-height: calc(108vh - 260px);
}

::v-deep .b-table-sticky-header {
	margin-bottom: 0rem;
}

.xray {
	margin: 0px 0px 0px 0px;
}

.table-container {
	height: calc(100vh - 225px);
	overflow: visible;
	overflow-y: scroll;
}

.b-table-sticky-header {
	overflow-y: auto;
}

.pagination-bar {
	width: 97%;
	margin: auto;
	position: fixed;
	bottom: 0px;
}

.common-btn {
	font-size: 1.3rem;
	margin: 0px 3px 0px 0px;
	padding: 0px;
	border: 0px;
	border-radius: 0px;
	cursor: pointer;
}

.per-page {
	margin: 0px 10px 0px 0px;
}

.mp {
	margin: 0px !important;
	padding: 0px !important;
}

.scan-bar {
	border: none;
	margin-bottom: 1.5rem !important;
}
</style>